<script setup lang="ts">
defineProps<{
  large?: boolean
  light?: boolean
}>()

const email = ref(undefined)

const { data, status, execute } = await useFetch('/api/newsletter', {
  body: computed(() => ({ email: email.value })),
  watch: false,
  method: 'POST',
  immediate: false,
})

const emailStatus = {
  active: {
    text: 'Jesteś już naszym subskrybentem!',
    color: 'text-green-200',
  },
  pending: {
    text: 'Jesteś na liście ale musisz potwierdzić adres email.',
    color: 'text-primary',
  },
  validating: {
    text: 'Zapisano! Potwierdź teraz adres email.',
    color: 'text-green-200',
  },
}
</script>

<template>
  <div
    class="py-8 shadow-2xl"
    :class="[light ? 'bg-gradient-to-b from-gray-700 via-gray-700 to-gray-800' : 'bg-gradient-to-br from-gray-950 via-gray-900 to-gray-800']"
  >
    <UContainer>
      <div class="flex flex-col lg:flex-row gap-8">
        <hgroup class="flex-1 space-y-2">
          <h2
            class="text-gray-50 font-semibold"
            :class="[large ? 'text-3xl' : 'text-xl']"
          >
            Newsletter Bitcoin.pl
          </h2>
          <p
            class="text-gray-400"
            :class="[large ? 'text-lg' : 'text-sm']"
          >
            Więcej niż bitcoin i kryptowaluty. Najważniejsze newsy i insiderskie informacje prosto na Twój email.
          </p>
        </hgroup>
        <section class="flex-1 space-y-2 w-full">
          <section
            v-if="data"
            class="flex-1 bg-gray-800 border border-gray-900 px-4 py-2 rounded flex items-center gap-2"
            :class="emailStatus[data?.status]?.color"
          >
            <UIcon
              class="size-5"
              name="i-heroicons-information-circle"
            />
            <p
              class="grow text-sm font-medium"
              data-test-id="newsletterResponse"
            >
              {{ emailStatus[data?.status]?.text }}
            </p>
            <UButton
              variant="ghost"
              size="md"
              color="red"
              class="dark"
              icon="i-heroicons-x-circle"
              @click="data = undefined"
            />
          </section>
          <form
            v-else
            class="flex flex-col sm:flex-row gap-2"
            @submit.prevent="execute()"
          >
            <UInput
              v-model="email"
              :disabled="status === 'pending'"
              required
              color="gray"
              size="xl"
              placeholder="Wpisz swój adres email..."
              class="dark w-full"
              type="email"
              data-test-id="newsletterEmailInput"
            />
            <UButton
              :loading="status === 'pending'"
              size="xl"
              class="dark sm:w-44"
              block
              type="submit"
              data-test-id="subscribeButton"
            >
              Zapisuję się
            </UButton>
          </form>
          <div>
            <p class="mt-3 text-sm text-gray-400">
              Dbamy o ochronę Twoich danych. Przeczytaj naszą
              <NuxtLink
                to="/polityka-prywatnosci"
                class="font-medium text-gray-50 underline hover:text-primary transition-colors"
              >
                Politykę Prywatności
              </NuxtLink>
            </p>
          </div>
        </section>
      </div>
    </UContainer>
  </div>
</template>
